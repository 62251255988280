import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { EnvironmentService } from '../environment/environment.service';

import { LoginContext, AuthenticateResponse, RefreshResponse, TokenStructure } from './authentication.interface';

import jwt_decode from 'jwt-decode';
import { LayoutService } from '@app/layout/service/app.layout.service';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private layoutService: LayoutService,
    private msalService: MsalService
  ) {}

  async loginAAD(token: string): Promise<string> {
    const payload = {
      Credentials: token
    };

    const $post = this.http
      .noLoader()
      .delay(2000)
      .post<AuthenticateResponse>(environment.routes.authenticateAD('azure'), payload);
      
      const res = await firstValueFrom($post).then(async resp => {
        let tokenInfo = jwt_decode(resp.token) as TokenStructure; // decode token

          tokenInfo.exp = new Date(0).setUTCSeconds(tokenInfo.exp);
          tokenInfo.iat = new Date(0).setUTCSeconds(tokenInfo.iat);
          tokenInfo.nbf = new Date(0).setUTCSeconds(tokenInfo.nbf);


          const res = this.environmentService.saveCurrentUserFromAuthenticate(resp.token, tokenInfo, resp.refreshToken);
          return tokenInfo.id;
      });

      return res;
  }

  login(context: LoginContext): Observable<boolean> {
    context.username = context.username.toLowerCase();

    const plainAuth = context.username + ':' + context.password;

    const base64Auth = btoa(plainAuth);

    const payload = {
      Credentials: base64Auth
    };

    return this.http
      .noLoader()
      .delay(2000)
      .post<AuthenticateResponse>(environment.routes.authenticate(), payload)
      .pipe(
        map((resp) => {
          let tokenInfo = jwt_decode(resp.token) as TokenStructure; // decode token

          tokenInfo.exp = new Date(0).setUTCSeconds(tokenInfo.exp);
          tokenInfo.iat = new Date(0).setUTCSeconds(tokenInfo.iat);
          tokenInfo.nbf = new Date(0).setUTCSeconds(tokenInfo.nbf);

          return this.environmentService.saveCurrentUserFromAuthenticate(resp.token, tokenInfo, resp.refreshToken);
        })
      );
  }

  refresh(refreshToken: string): Observable<RefreshResponse> {

    const body = { token: refreshToken };

    return this.http
      .noLoader()
      .delay(1000)
      .post<RefreshResponse>(environment.routes.refresh(), body)
      .pipe(
        map((resp) => {
          this.environmentService.refreshTokenSuccess(resp);

          return resp;
        })
      );
  }

  logout(): Observable<boolean> {
    return this.http
      .noLoader()
      .delay(200)
      .post<boolean>(environment.routes.logout(), { token: this.environmentService.currentCredentialsSubject.getValue()?.refreshToken })
      .pipe(
        map(() => {
        this.environmentService.clearUser();
        this.environmentService.clearTokenData();
        this.layoutService.resetTheme();

        this.msalService.instance.logoutPopup();
        
        return true;
      }));
  }
}
