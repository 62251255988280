import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/environment/environment.service';
import { AREACONFIG, availableAreas } from 'src/configs/areas.constant';

@Injectable()
export class AreasService {

  constructor(private env: EnvironmentService) {
  }

  getAvailableAreas(): AREACONFIG[] {
    return this.recursiveFilter(availableAreas)
    // return AREAS;
  }

  recursiveFilter(areas: AREACONFIG[]) {
    var matches: AREACONFIG[] = [];
    if (!areas) return matches;

    areas.forEach((area) => {
      if (this.filterBool(area)) {
        matches.push(area);
        let childResults = this.recursiveFilter(area.subAreas!);
        if (childResults.length > 0) {
          area.subAreas = childResults;
        } else {
          area.subAreas = undefined;
        }
      }
    });

    return matches;
  }

  filterBool(area: AREACONFIG) {
    // @ts-ignore
    if (area.callback && this[area.callback]) {
      // @ts-ignore
      return this.env.doesUserHavePrivilege(area.privilege!) && this[area.callback].call(this, area);
    } else {
      return this.env.doesUserHavePrivilege(area.privilege!);
    }
  }

  getAllAreas(): AREACONFIG[] {
    return availableAreas;
  }

  getArea(area: string): AREACONFIG {
    // @ts-ignore
    return this.getAvailableAreas()?.find((a) => a.code === area);
  }

  /**
   * Area Callbacks
   */
  private callback(area: AREACONFIG): boolean {
    // logic
    return true;
  }
}
