import { COUNTRIES, COUNTRY, COUNTRYCONFIG, CURRENCY, LANGUAGE } from 'src/configs/countries.constant';

export class EnvironmentClass {
  public defaultCountry: COUNTRYCONFIG;
  public defaultLanguage: LANGUAGE;
  public defaultCurrency: CURRENCY;

  constructor(defaultCountry: COUNTRY) {
    this.defaultCountry = COUNTRIES.find((country) => country.code === defaultCountry) ?? COUNTRIES[0];
    this.defaultLanguage = this.defaultCountry.language;
    this.defaultCurrency = this.defaultCountry.currency;
  }

  public get supportedLanguages(): LANGUAGE[] {
    const supportedLanguages = COUNTRIES.map((country) => {
      return country.language;
    });

    return supportedLanguages;
  }
}
