import { IconName } from "@fortawesome/free-solid-svg-icons";
import { PRIVILEGE } from "./privileges.constant";
import { ROLE } from "./roles.constant";

export enum MACROAREA {
    PEOPLE = 'areas.people',
    PAYROLL = 'areas.payroll',
    SALES = 'areas.sales',
    TALENT = 'areas.talent'
  }
  
  export enum AREA {
    PEOPLE_MYAREA = 'areas.people.myarea',
    PEOPLE_PROFILE = 'areas.people.profile',
    PEOPLE_JOURNEY = 'areas.people.journey',
    PEOPLE_ADMIN = 'areas.people.admin',
    PAYROLL_MY = 'areas.payroll.my',
    PAYROLL_ADMIN = 'areas.payroll.admin',
    SALES_LEADS = 'areas.sales.leads',
    SALES_ADMIN = 'areas.sales.admin',
    TALENT_DASHBOARD = 'areas.talent.dashboard',
    TALENT_JOBS = 'areas.talent.jobs',
    TALENT_APPLICANTS = 'areas.talent.applicants',
    TALENT_ADMIN = 'areas.talent.admin',
    TALENT_PROFILE_GENERATOR = 'areas.talent.profile-generator'
  }
  
  export enum SUBAREA {
    PEOPLE_ADMIN_USER = 'areas.people.admin.user',
    PEOPLE_ADMIN_GOALS = 'areas.people.admin.goals',
    PEOPLE_ADMIN_USER_EDIT = 'areas.people.admin.user.edit',
    SALES_LEADS_TIMELINE = 'areas.sales.leads.timeline',
    SALES_LEADS_DATA = 'areas.sales.leads.data',
    SALES_ADMIN_CUSTOMER = 'areas.sales.admin.customer',
    TALENT_APPLICANTS_EDIT = 'areas.talent.applicants.edit',
    TALENT_PROFILE_GENERATOR_EDIT = 'areas.talent.profile-generator.edit'
  }

export interface AREACONFIG {
    code: MACROAREA | AREA | SUBAREA;
    name: string;
    icon?: IconName;
    privilege?: PRIVILEGE;
    role?: ROLE;
    route?: string;
    routerLinkActiveOptions?: RouterLinkActiveOptions;
    callback?: string;
    badge?: string;
    visible?: boolean;
    subAreasVisible?: boolean;
    subAreas?: AREACONFIG[];
}

export interface RouterLinkActiveOptions {
    paths: "exact" | "subset";
    queryParams: "exact" | "subset" | "ignored";
    matrixParams: "exact" | "subset" | "ignored";
    fragment: "exact" | "ignored";
}

export const availableAreas: AREACONFIG[] = [
    {
        code: MACROAREA.PEOPLE,
        name: 'macroAreas.people',
        icon: 'users',
        privilege: PRIVILEGE.PEOPLEUSERREAD,
        subAreas: [
            { code: AREA.PEOPLE_MYAREA, name: 'areas.my-area', icon: 'home', privilege: PRIVILEGE.PEOPLEUSERREAD, route: '/app/people' },
            { code: AREA.PEOPLE_PROFILE, name: 'areas.profile', icon: 'user', privilege: PRIVILEGE.PEOPLEUSERREAD, route: '/app/people/me' },
            { code: AREA.PEOPLE_JOURNEY, name: 'areas.journey', icon: 'compass', privilege: PRIVILEGE.PEOPLEUSERREAD, route: '/app/people/journey' },
            { code: AREA.PEOPLE_ADMIN, name: 'areas.admin', icon: 'screwdriver-wrench', privilege: PRIVILEGE.PEOPLEUSERREADWRITEALL, route: '/app/people/admin', subAreasVisible: true, subAreas: [
                { code: SUBAREA.PEOPLE_ADMIN_USER, name: 'subAreas.user', icon: 'user-edit', privilege: PRIVILEGE.PEOPLEUSERREADWRITEALL, route: '/app/people/admin/users', subAreas: [
                    { code: SUBAREA.PEOPLE_ADMIN_USER_EDIT, name: 'subAreas.user.edit', privilege: PRIVILEGE.PEOPLEUSERREADWRITEALL, route: '\/app\/people\/admin\/users\/[0-9a-zA-Z-]+', visible: false }
                ] },
                { code: SUBAREA.PEOPLE_ADMIN_GOALS, name: 'subAreas.goals', icon: 'chart-pie', privilege: PRIVILEGE.ORGANIZATIONGOALSREADWRITE, route: '/app/people/admin/goals' }
            ] },
        ]
    },
    {
        code: MACROAREA.PAYROLL,
        name: 'macroAreas.payroll',
        icon: 'file-invoice-dollar',
        privilege: PRIVILEGE.FINANCEPAYROLLREAD,
        subAreas: [
            { code: AREA.PAYROLL_MY, name: 'areas.payroll-my', icon: 'file-invoice-dollar', privilege: PRIVILEGE.FINANCEPAYROLLREAD, route: '/app/payroll' },
            { code: AREA.PAYROLL_ADMIN, name: 'areas.admin', icon: 'screwdriver-wrench', privilege: PRIVILEGE.FINANCEPAYROLLREADWRITEALL, route: '/app/payroll/admin' },
        ]
    },
    {
        code: MACROAREA.SALES,
        name: 'macroAreas.sales',
        icon: 'magnifying-glass-dollar',
        privilege: PRIVILEGE.SALESLEADREAD,
        subAreas: [
            { code: AREA.SALES_LEADS, name: 'areas.leads', icon: 'magnifying-glass-dollar', privilege: PRIVILEGE.SALESLEADREAD, route: '/app/sales/leads', subAreasVisible: true, subAreas: [
                { code: SUBAREA.SALES_LEADS_TIMELINE, name: 'subAreas.timeline', icon: 'briefcase', privilege: PRIVILEGE.SALESLEADREAD, route: '/app/sales/leads/timeline' },
                { code: SUBAREA.SALES_LEADS_DATA, name: 'subAreas.data', icon: 'table-list', privilege: PRIVILEGE.SALESLEADREAD, route: '/app/sales/leads/data' }
            ]},
            { code: AREA.SALES_ADMIN, name: 'areas.admin', icon: 'screwdriver-wrench', privilege: PRIVILEGE.SALESCUSTOMERREADWRITEALL, route: '/app/sales/admin', subAreasVisible: true, subAreas: [
                { code: SUBAREA.SALES_ADMIN_CUSTOMER, name: 'subAreas.customer', icon: 'building', privilege: PRIVILEGE.SALESCUSTOMERREADWRITEALL, route: '/app/sales/admin/customer' }
            ]},
        ]
    },
    {
        code: MACROAREA.TALENT,
        name: 'macroAreas.talent',
        icon: 'gem',
        privilege: PRIVILEGE.TALENTAPPLICANTREAD,
        subAreas: [
            { code: AREA.TALENT_DASHBOARD, name: 'areas.dashboard', icon: 'home', privilege: PRIVILEGE.TALENTAPPLICANTREAD, route: '/app/talent/' },
            { code: AREA.TALENT_JOBS, name: 'areas.jobs', icon: 'folder-open', privilege: PRIVILEGE.TALENTJOBREAD, route: '/app/talent/jobs' },
            { code: AREA.TALENT_APPLICANTS, name: 'areas.applicants', icon: 'user-graduate', privilege: PRIVILEGE.TALENTAPPLICANTREAD, route: '/app/talent/applicants', subAreasVisible: false, subAreas: [
                { code: SUBAREA.TALENT_APPLICANTS_EDIT, name: 'areas.applicants.edit', privilege: PRIVILEGE.TALENTAPPLICANTREADWRITEALL, route: '\/app\/talent\/applicants\/[0-9a-zA-Z-]+', visible: false }
            ] },
            { code: AREA.TALENT_PROFILE_GENERATOR, name: 'areas.profile-generator', icon: 'file-pdf', privilege: PRIVILEGE.TALENTPROFILEREAD, route: '/app/talent/profile-generator', visible: false, subAreasVisible: false, subAreas: [
                { code: SUBAREA.TALENT_PROFILE_GENERATOR_EDIT, name: 'areas.profile-generator.edit', privilege: PRIVILEGE.TALENTPROFILEREADWRITE, route: '\/app\/talent\/profile-generator\/[0-9a-zA-Z-]+', visible: false }
            ] },
            { code: AREA.TALENT_ADMIN, name: 'areas.admin', icon: 'screwdriver-wrench', privilege: PRIVILEGE.TALENTJOBREADWRITEALL, route: '/app/talent/admin' },
        ]
    }
];