import { Component } from '@angular/core';
import { 
    faTable,
    faCircleQuestion,
    faUnlock
     
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
})
export class NotfoundComponent { 
    faTable = faTable;
    faCircleQuestion = faCircleQuestion;
    faUnlock = faUnlock;
}