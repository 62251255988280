import { Injectable } from '@angular/core';
import { IdentityService } from '@app/compass/services/identity.service';
import { EnvironmentService } from '@app/core/environment/environment.service';
import { I18nService } from '@app/core/i18n/i18n.service';
import { Logger } from '@app/core/logger/logger.service';
import { COUNTRIES, COUNTRY } from '@conf/countries.constant';
import { User, UserConfig } from '@app/compass/interfaces/identity.interface';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { THEME } from '../interfaces/layout.interfaces';

const log = new Logger('LayoutService');

interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService{

    defaultConfig: UserConfig = {
        colorScheme: 'light',
        scale: 14,
        country: COUNTRY.PT
    };

    state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false
    };

    private configUpdate = new BehaviorSubject<UserConfig | undefined>(this.defaultConfig);
    private overlayOpen = new Subject<any>();
    private currentUser = new BehaviorSubject<User | null>(null);

    configUpdate$ = this.configUpdate;
    overlayOpen$ = this.overlayOpen.asObservable();
    currentUser$ = this.currentUser;

    private updateUserConfigs: Subscription = new Subscription;

    constructor(
        private envService: EnvironmentService,
        private identityService: IdentityService,
        private messageService: MessageService,
        private translateService: TranslateService,
        private i18nService: I18nService
    ) {
        this.envService.currentUserSubject.subscribe((user) => {
            this.currentUser.next(user);
            
            if(user?.configs)
                this.configUpdate.next(user?.configs);
            
            this.applyScale(user?.configs?.scale, false);
            this.changeLanguage(user?.configs?.country, false);
            this.changeTheme(user?.configs?.colorScheme, false);
        });
    }

    onMenuToggle() {
        if (this.isDesktop()) {
            this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
        }
        else {
            this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    showProfileSidebar() {
        this.state.profileSidebarVisible = !this.state.profileSidebarVisible;
        if (this.state.profileSidebarVisible) {
            this.overlayOpen.next(null);
        }
    }

    showConfigSidebar() {
        this.state.configSidebarVisible = true;
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return !this.isDesktop();
    }

    onConfigUpdate() {
        const currentUser = this.currentUser.getValue();

        if(currentUser)
            if(this.configUpdate.value)
                this.updateUserConfigs = this.identityService.updateUserConfigs(currentUser.id, this.configUpdate.value).subscribe({
                    next: () => {
                        this.messageService.add({ severity: 'success', summary: this.translateService.instant('app.configs.update.success.title'), detail: this.translateService.instant('app.configs.update.success.message') });
                    },
                    complete: () => {
                        this.updateUserConfigs.unsubscribe();
                    }
                });
    }

    decrementScale() {
        const actualConfig = this.configUpdate.value;
        if(actualConfig) {
            actualConfig.scale--;
            this.applyScale(actualConfig.scale, true);
        }
    }

    incrementScale() {
        const actualConfig = this.configUpdate.value;
        if(actualConfig){
            actualConfig.scale++;
            this.applyScale(actualConfig.scale, true);
        }
    }

    applyScale(scale?: number, updateDB?: boolean) {
        if(this.configUpdate.value != scale) {
            document.documentElement.style.fontSize = scale + 'px';

            if(updateDB)
                this.onConfigUpdate();
        }
    }
    resetTheme(){
        this.changeTheme('light', false);
    }

    changeTheme(colorScheme?: string, updateDB?: boolean) {
        const colorSchemeProp = colorScheme ?? this.configUpdate.value?.colorScheme;
        const theme: string = colorSchemeProp == 'light' ? THEME.LIGHT : THEME.DARK;
        const previousTheme = colorSchemeProp == 'light' ? THEME.DARK : THEME.LIGHT;

        const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
        const newHref = themeLink.getAttribute('href')!.replace(previousTheme, theme);
        this.replaceThemeLink(newHref, () => {
            this.configUpdate.next({...this.configUpdate.value as UserConfig, colorScheme: colorSchemeProp ?? 'light' })
            
            if(updateDB) 
                this.onConfigUpdate();
        });
    }

    replaceThemeLink(href: string, onComplete: Function) {
        const id = 'theme-css';
        const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', `${id}-clone`);
        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            var themeLinks = document.querySelectorAll('#theme-css');
            
            for(let i = 0; i < themeLinks.length; i++)
                themeLinks[i].remove();
            
            cloneLinkElement.setAttribute('id', id);
            onComplete();
        });
    }

    changeLanguage(countryCode?: string, updateDB?: boolean) {
        const country = COUNTRIES.find(x => x.code == countryCode) ?? this.envService.selectedCountry;
        this.envService.selectedCountry = country;
        this.i18nService.language = country.language.code;
        this.configUpdate.next({...this.configUpdate.value as UserConfig, country: country.code});
        
        if(updateDB)
            this.onConfigUpdate();
    }

}
