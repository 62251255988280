import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpEventType,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';

import { mockConfiguration } from '@mock/conf.constant';
import { HTTPCODE } from '../http-codes.constant';

import { environment } from '@env/environment';
import { Logger } from '@app/core/logger/logger.service';

const log = new Logger('Mock Interceptor');

interface MOCKRESPONSE {
  data: any;
  status: HTTPCODE;
}

@Injectable()
export class MockInterceptor implements HttpInterceptor {
  private simulateDelayDuration = 0;

  constructor() {}

  configure(delay: number): MockInterceptor {
    const instance = new MockInterceptor();

    instance.simulateDelayDuration = delay;

    return instance;
  }

  /**
   * Mock the API response
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const mockResponse = mockConfiguration.find((conf) => {

      const regex = `${environment.directServerUrl}${conf.apiRoute}$`;

      return conf.method === request.method && RegExp(regex).test(request.urlWithParams);
    });

    if (environment.mock && mockResponse && mockResponse.mock) {

      return new Observable((subscriber: Subscriber<HttpEvent<any>>) => {

        import(`src/mock/data/${mockResponse.mockData}`).then((data: MOCKRESPONSE) => {

          const status = data.status ?? HTTPCODE.OK;

          subscriber.next({
            type: HttpEventType.Sent,
          });

          setTimeout(() => {

            log.debug('Request Intercepted', {
              url: request.url,
              params: request.params,
              verb: request.method,
              request: request.body,
              response: data.data,
            });

            if (status === HTTPCODE.OK) {

              subscriber.next(new HttpResponse({ status, body: data.data }));
              subscriber.complete();

            } else {

              subscriber.error(new HttpErrorResponse({ status, error: data.data }));
            }

          }, this.simulateDelayDuration);

        }, (error) => {

          subscriber.error(error);
        });
      });
    } else {
      return next.handle(request);
    }
  }
}
