import { registerLocaleData } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { COUNTRIES } from '@conf/countries.constant';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { filter, map, merge, mergeMap, Subscription } from 'rxjs';
import { IdentityService } from './compass/services/identity.service';
import { EnvironmentService } from './core/environment/environment.service';
import { I18nService } from './core/i18n/i18n.service';
import { LoaderService } from './core/loader/loader.service';
import { Logger } from './core/logger/logger.service';

const log = new Logger('App');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    title = 'compass-client';

    private locale: string;
    isLoading: boolean = false;
    private sub: Subscription = new Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
        private i18nService: I18nService,
        private loader: LoaderService,
        private app: EnvironmentService,
        private identityService: IdentityService,
        @Inject(LOCALE_ID) locale: string,
        private primengConfig: PrimeNGConfig,
        private loaderService: LoaderService
        ) {
            this.locale = locale;
        }

    ngOnInit() {
        if (environment.production) {
            Logger.enableProductionMode();
        } else {
            log.debug('Environment Variables: ', environment);
        }

        log.debug('App starting...');

        const currentUser = this.app.currentUserSubject.getValue();

        if(this.app.isAuthenticated() && currentUser)
            this.getUserData(currentUser.id);

        this.primengConfig.ripple = true;

        const defaultCountry = this.app.envClass.defaultCountry;
        const supportedLanguages = this.app.envClass.supportedLanguages;

        this.i18nService.init(defaultCountry.language.code, supportedLanguages);

        if (this.locale === 'en-GB') {
            import('@angular/common/locales/en-GB').then(
              (module) => {
                registerLocaleData(module.default, 'en-GB');
              },
              (error) => {
                log.error('Unable to load a locale successfully.');
              }
            );
        } else if (this.locale === 'pt-PT') {
        import('@angular/common/locales/pt-PT').then(
            (module) => {
                registerLocaleData(module.default, 'pt-PT');
            },
            (error) => {
                log.error('Unable to load a locale successfully.');
            }
        );
        } else {
            log.error('The defined locale is not supported.');
        }
      
        this.router.events.subscribe((event) => {
        if (event instanceof RouteConfigLoadStart) {
            this.loader.increaseCallCount();
        } else if (event instanceof RouteConfigLoadEnd) {
            this.loader.decreaseCallCount();
        }
        });

        const onNavigationEnd = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        );
      
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                let route = this.activatedRoute;
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
            )
            .subscribe((event) => {
                const title = event['title'];
        
                if (title) {
                this.titleService.setTitle(
                    `Compass - ${this.translateService.instant(title)}`
                );
                }
            });

        this.sub = this.loaderService.LoaderNotification.subscribe(res => {
            this.isLoading = res.show
        })
    }

    getUserData(id: string){
        const expand: string = 'all';

        const subU = this.identityService.getUserById(id, expand).subscribe({
            next: (result) => {
                if (result) {
                    const configs = result.configs;
                    
                    this.app.saveCurrentUser(result);
                    this.app.saveConfigs(configs);
                    this.i18nService.language = COUNTRIES.find(x => x.code == configs?.country)?.language.code;
                }
            },
            complete: () => {
                subU.unsubscribe();
            },
            error: () => {
                subU.unsubscribe();
            } 
        });
    }

    ngOnDestroy() {
        this.i18nService.destroy();
        this.sub.unsubscribe();
    }
}
