import { SupportedLanguage } from '@app/core/i18n/i18n.service';

export enum COUNTRY {
  PT = 'PT',
  GB = 'GB'
}

export interface LANGUAGE extends SupportedLanguage {
 locale: string;
}

export interface CURRENCY {
  code: string;
  label: string;
}

export interface COUNTRYCONFIG {
  code: COUNTRY;
  name: string;
  icon: string;
  language: LANGUAGE;
  currency: CURRENCY;
}

export const COUNTRIES: COUNTRYCONFIG[] = [
  {
    code: COUNTRY.PT,
    name: 'Portugal',
    icon: 'flag flag-pt',
    language: {
      code: 'pt-PT',
      label: 'Português',
      locale: 'pt-PT',
      orientation: 'ltr',
    },
    currency: {
      code: 'EUR',
      label: 'Euro',
    },
  },
  {
    code: COUNTRY.GB,
    name: 'Great Britain',
    icon: 'flag flag-gb',
    language: {
      code: 'en-GB',
      label: 'English',
       locale: 'en-GB',
      orientation: 'ltr',
    },
    currency: {
      code: 'GBP',
      label: 'Pound sterling',
    },
  }
];
