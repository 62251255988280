export const mockConfiguration = [
  //AUTH
  {
    apiRoute: '\/v1\/identity\/login\/[a-z]*',
    method: 'POST',
    mockData: 'login',
    mock: false
  },
  {
    apiRoute: '\/v1\/identity\/logout',
    method: 'POST',
    mockData: 'logout',
    mock: false
  },
  {
    apiRoute: '\/v1\/identity\/token\/refresh',
    method: 'POST',
    mockData: 'refreshToken',
    mock: false
  },
  //USERS
  {
    apiRoute: '\/v1\/identity\/user',
    method: 'GET',
    mockData: 'getUsers',
    mock: false
  },
  {
    apiRoute: '\/v1\/identity\/org\/chart',
    method: 'GET',
    mockData: 'getOrgChartData',
    mock: false
  },
  {
    apiRoute: '\/v1\/identity\/user\/[0-9a-zA-Z-]+',
    method: 'GET',
    mockData: 'getUserById',
    mock: false
  },
  {
    apiRoute: '\/v1\/identity\/user\/[0-9a-zA-Z-]+\\?expand=[0-9a-zA-Z,]+',
    method: 'GET',
    mockData: 'getUserByIdExpanded',
    mock: false
  },
  {
    apiRoute: '\/v1\/identity\/user\/[0-9a-zA-Z-]+',
    method: 'PATCH',
    mockData: 'updateUser',
    mock: false
  },
  //TALENT
  {
    apiRoute: '\/v1\/talent\/applicant',
    method: 'GET',
    mockData: 'getApplicants',
    mock: false
  },
  {
    apiRoute: '\/v1\/talent\/applicant\/[0-9]+',
    method: 'GET',
    mockData: 'getApplicantById',
    mock: false
  }
];