import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, filter, takeUntil } from 'rxjs';

//import { Credential, UserDetails, EnvUser } from './environment.interface';
import { currentUserKey, currentCredentialsKey } from './environment.constant';

import { RefreshResponse, TokenStructure } from '../authentication/authentication.interface';
import { PRIVILEGE } from 'src/configs/privileges.constant';

import { Logger } from '../logger/logger.service';
import { EnvironmentClass } from './environment.class';
import { ENV } from 'src/configs/injection-tokens.constant';

import { COUNTRIES, COUNTRYCONFIG } from 'src/configs/countries.constant';
import { Credentials, User, UserConfig } from '@app/compass/interfaces/identity.interface';
import { ROLE } from '@conf/roles.constant';

const log = new Logger('Environment Service');

@Injectable()
export class EnvironmentService {
  /**
   * Session Linked Variables
   */
  private _currentUser: User | null;
  public currentUserSubject: BehaviorSubject<User | null>;

  private _currentCredentials: Credentials | null;
  public currentCredentialsSubject: BehaviorSubject<Credentials | null>;
  /**
   * View Flags
   */
  private _selectedCountry: COUNTRYCONFIG;

  /**
   * Environment Class
   */
  private _env: EnvironmentClass;

  constructor(
    @Inject(ENV) env: EnvironmentClass
    ) {
    const _currentUserKey = localStorage.getItem(currentUserKey);
    const _currentCredentialsKey = localStorage.getItem(currentCredentialsKey);

    this._currentUser = _currentUserKey ? JSON.parse(_currentUserKey) : null;
    this.currentUserSubject = new BehaviorSubject<User | null>(this._currentUser);
    this._currentCredentials = _currentCredentialsKey ? JSON.parse(_currentCredentialsKey) : null;
    this.currentCredentialsSubject = new BehaviorSubject<Credentials | null>(this._currentCredentials);
    this._env = env;
    this._selectedCountry = env.defaultCountry;
  }

  /**
   * Authentication Helpers
   */

  isAuthenticated(): boolean {
    const token: string = this._currentCredentials?.token ?? '';

    return token !== '';
  }

  refreshTokenSuccess(data: RefreshResponse): void {
    if (data.error || !data.token) {
      this.saveCredentials();
    } else {
      const credentials: Credentials = {
        token: data.token,
        refreshToken: data.refreshToken
      };

      this.saveCredentials(credentials);
    }
  }

  refreshTokenError(): void {
    this.saveCredentials();
  }

  saveCredentials(credential?: Credentials): void {
    this._currentCredentials = credential == null ? null : Object.assign({}, this._currentCredentials, credential) ?? null;
    this.currentCredentialsSubject.next(this._currentCredentials);

    if (credential) {
      localStorage.setItem(currentCredentialsKey, JSON.stringify(this._currentCredentials));
    } else {
      localStorage.removeItem(currentCredentialsKey);
    }
  }

  savePrivileges(privileges?: string[]): void {
    this._currentUser = Object.assign({}, this._currentUser, { privileges: privileges ?? null });

    this.saveCurrentUser(this._currentUser);
  }

  saveRoles(roles?: string[]) {
    this._currentUser = Object.assign({}, this._currentUser, { roles : roles ?? null });

    this.saveCurrentUser(this._currentUser);
  }

  saveConfigs(configs?: UserConfig) {
    this._currentUser = Object.assign({}, this._currentUser, { configs : configs ?? null });
    this.selectedCountry = COUNTRIES.find(x => x.code == this._currentUser?.configs?.country) ?? this.selectedCountry;

    this.saveCurrentUser(this._currentUser);
  }

  doesUserHavePrivilege(privilege: PRIVILEGE | string): boolean {
    if (this._currentUser && this._currentUser.privileges && this._currentUser.privileges.length > 0) {
      return !!this._currentUser.privileges.find((priv) => priv === privilege);
    }
    return false;
  }

  doesUserHaveRole(role: ROLE | string): boolean {
    if (this._currentUser && this._currentUser.roles && this._currentUser.roles.length > 0) {
      return !!this._currentUser.roles.find((r) => r === role);
    }
    return false;
  }

  isSelfUser(userId: string): boolean {

    const isSelfUser = this._currentUser?.id === userId;

    return isSelfUser;
  }

  userIsMemberOfRole(role: string): boolean {
    if (this._currentUser && this._currentUser.roles) {
      return this._currentUser.roles.includes(role);
    }
    return false;
  }
  userIsMemberOfAllRoles(roles: string[]): boolean {
    const role = roles.pop();
    return role === undefined ? true : this.userIsMemberOfRole(role) && this.userIsMemberOfAllRoles(roles);
  }

  saveCurrentUser(user?: User): void {
    this._currentUser = user == null ? null : Object.assign({}, this._currentUser, user) ?? null;
    this.currentUserSubject.next(this._currentUser);

    if (user) {
      localStorage.setItem(currentUserKey, JSON.stringify(this._currentUser));
    } else {
      localStorage.removeItem(currentUserKey);
    }
  }

  saveCurrentUserFromAuthenticate(token: string, data: TokenStructure, refreshToken?: string): boolean {
    if (data && data.id) {
      this.saveCurrentUser({
        id: data.id,
        privileges: data.permission,
        roles: data.role
      });

      this.saveCredentials({
        token,
        refreshToken
      });

      return true;
    }

    return false;
  }

  get currentUserConfigs(): UserConfig | undefined {
    return this._currentUser?.configs;
  }

  clearUser(): void {
    this.saveCurrentUser();
  }

  clearTokenData(): void {
    const keys = Object.keys(localStorage);

    keys.forEach(k => {
      if(k.includes("login.windows.net"))
      localStorage.removeItem(k);
    });

    this.saveCredentials();
  }


  /**
   * App State Helpers
   */

  public get envClass(): EnvironmentClass {
    return this._env;
  }

  public get selectedCountry(): COUNTRYCONFIG{
    return this._selectedCountry;
  }

  public set selectedCountry(country: COUNTRYCONFIG){
    this._selectedCountry = country;

  }
}
