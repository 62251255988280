import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../logger/logger.service';
import { AreasService } from '../areas/areas.service';
import { AREACONFIG } from '@conf/areas.constant';

const log = new Logger('AuthorizationGuard');

@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(
    private router: Router,
    private area: AreasService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    if(route.url[0] == null){
      return true;
    }

    const availableAreas = this.area.getAvailableAreas();
    var foundArea = this.recursiveCheck(route.url[0].path,state.url, availableAreas);

    if (foundArea) {
      return true;
    }

    log.debug(`The user does not have the right privilege to see the ${route.url[0].path} - ${state.url} route.`);
    this.router.navigate(['auth','access']);

    return false;
  }

  recursiveCheck(routeUrlPath:string ,url: string, areas?: AREACONFIG[]): any {
    return (
      areas &&
      areas.some(
        (entry) =>
          entry.route == routeUrlPath || entry.route == url || this.validateRegex(url, entry.route) || this.recursiveCheck(routeUrlPath, url, entry.subAreas)
      )
    );
  }

  validateRegex(route: string, regex?: string){
    if(!regex) return false;

    regex = `${regex}$`;

    return RegExp(regex).test(route)
  }
}
