import { ChangeDetectorRef, Component, Host, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from './app.menu.service';
import { LayoutService } from './service/app.layout.service';
import { AREACONFIG } from '@conf/areas.constant';
import { 
    faAngleDown,
    faCircleQuestion,
    faUsers,
    faHome,
    faUser,
    faCompass,
    faScrewdriverWrench,
    faAddressBook,
    faBuilding,
    faUserEdit,
    faChartPie,
    faFileInvoiceDollar,
    faCalculator,
    faMagnifyingGlassDollar,
    faShoppingCart,
    faChartLine,
    faBriefcase,
    faTableList,
    faGem,
    faFolderOpen,
    faUserGraduate
} from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-menuitem]',
    templateUrl: 'app.menuitem.component.html',
    animations: [
        trigger('children', [
            state('collapsed', style({
                height: '0'
            })),
            state('expanded', style({
                height: '*'
            })),
            transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppMenuitemComponent implements OnInit, OnDestroy {

    @Input() area!: AREACONFIG;

    @Input() index!: number;

    @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;

    @Input() parentKey!: string;

    active = false;

    menuSourceSubscription: Subscription;

    menuResetSubscription: Subscription;

    key: string = "";

    constructor(public layoutService: LayoutService, private cd: ChangeDetectorRef, public router: Router, private menuService: MenuService, private library: FaIconLibrary) {

        library.addIcons(faAngleDown, faCircleQuestion, faUsers, faHome, faUser, faCompass,
            faScrewdriverWrench, faAddressBook, faBuilding, faUserEdit, faChartPie, faFileInvoiceDollar,
            faCalculator, faMagnifyingGlassDollar, faShoppingCart, faChartLine, faBriefcase,
            faTableList, faGem, faFolderOpen, faUserGraduate);

        this.menuSourceSubscription = this.menuService.menuSource$.subscribe(value => {
            Promise.resolve(null).then(() => {
                if (value.routeEvent) {
                    this.active = (value.key === this.key || value.key.startsWith(this.key + '-')) ? true : false;
                }
                else {
                    if (value.key !== this.key && !value.key.startsWith(this.key + '-')) {
                        this.active = false;
                    }
                }
            });
        });

        this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
            this.active = false;
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(params => {
                if (this.area.route) {
                    this.updateActiveStateFromRoute();
                }
            });
    }

    ngOnInit() {
        this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);

        if (this.area.route) {
            this.updateActiveStateFromRoute();
        }
    }

    updateActiveStateFromRoute() {
        if(this.area.route){
            let activeRoute = this.router.isActive(this.area.route, { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' });

            if (activeRoute) {
                this.menuService.onMenuStateChange({ key: this.key, routeEvent: true });
            }
        }
    }

    itemClick() {
        // toggle active state
        if (this.area.subAreas) {
            this.active = !this.active;
        }

        this.menuService.onMenuStateChange({ key: this.key });
    }

    get submenuAnimation() {
        return this.root ? 'expanded' : (this.active ? 'expanded' : 'collapsed');
    }

    @HostBinding('class.active-menuitem') 
    get activeClass() {
        return this.active && !this.root;
    }

    ngOnDestroy() {
        if (this.menuSourceSubscription) {
            this.menuSourceSubscription.unsubscribe();
        }

        if (this.menuResetSubscription) {
            this.menuResetSubscription.unsubscribe();
        }
    }
}
