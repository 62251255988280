import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AREACONFIG, availableAreas } from '@conf/areas.constant';
import { LayoutService } from './service/app.layout.service';
import { AreasService } from '@app/core/areas/areas.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    areas: AREACONFIG[] = [];

    constructor(
        private areasService: AreasService,
        public layoutService: LayoutService
    ) { }

    ngOnInit() {
        this.areas = this.areasService.getAvailableAreas();
    }
}
