export enum PRIVILEGE {
  AUTHREAD = "Auth.Read",
  AUTHREADWRITE ="Auth.ReadWrite",
  AUTHREADWRITEALL ="Auth.ReadWrite.All",
  PEOPLEADDRESSREADWRITE = "People.Address.ReadWrite",
  PEOPLEADDRESSREAD ="People.Address.Read",
  PEOPLEADDRESSREADWRITEALL ="People.Address.ReadWrite.All",
  PEOPLEUSERREADWRITE = "People.User.ReadWrite",
  PEOPLEUSERREAD ="People.User.Read",
  PEOPLEUSERREADWRITEALL ="People.User.ReadWrite.All",
  PEOPLEROLEREADWRITE = "People.Role.ReadWrite",
  PEOPLEROLEREAD ="People.Role.Read",
  PEOPLEROLEREADWRITEALL = "People.Role.ReadWrite.All",
  PEOPLEPRIVILEGEREADWRITEALL = "People.Privilege.ReadWrite.All",
  PEOPLEPRIVILEGEREADWRITE = "People.Privilege.ReadWrite",
  PEOPLEPRIVILEGEREAD ="People.Privilege.Read",
  SALESCUSTOMERREADWRITEALL ="Sales.Customer.ReadWrite.All",
  SALESCUSTOMERREADWRITE = "Sales.Customer.ReadWrite",
  SALESCUSTOMERREAD = "Sales.Customer.Read",
  SALESLEADREADWRITEALL = "Sales.Lead.ReadWrite.All",
  SALESLEADREADWRITE = "Sales.Lead.ReadWrite",
  SALESLEADREAD = "Sales.Lead.Read",
  COMMUNICATIONBLOGREADWRITEALL = "Communication.Blog.ReadWrite.All",
  COMMUNICATIONBLOGREADWRITE = "Communication.Blog.ReadWrite",
  COMMUNICATIONBLOGREAD = "Communication.Blog.Read",
  FINANCEEXPENSEREADWRITEALL = "Finance.Expense.ReadWrite.All",
  FINANCEEXPENSEREADWRITE = "Finance.Expense.ReadWrite",
  FINANCEEXPENSEREAD = "Finance.Expense.Read",
  FINANCEPAYROLLREADWRITEALL = "Finance.Payroll.ReadWrite.All",
  FINANCEPAYROLLREADWRITE = "Finance.Payroll.ReadWrite",
  FINANCEPAYROLLREAD = "Finance.Payroll.Read",
  TALENTPROFILEREADWRITEALL = "Talent.Profile.ReadWrite.All",
  TALENTPROFILEREADWRITE = "Talent.Profile.ReadWrite",
  TALENTPROFILEREAD = "Talent.Profile.Read",
  TALENTJOBREADWRITEALL = "Talent.Job.ReadWrite.All",
  TALENTJOBREADWRITE = "Talent.Job.ReadWrite",
  TALENTJOBREAD = "Talent.Job.Read",
  TALENTAPPLICANTREADWRITEALL = "Talent.Applicant.ReadWrite.All",
  TALENTAPPLICANTREADWRITE = "Talent.Applicant.ReadWrite",
  TALENTAPPLICANTREAD = "Talent.Applicant.Read",
  ORGANIZATIONGOALSREAD = "Organization.Goals.Read",
	ORGANIZATIONGOALSREADWRITE = "Organization.Goals.ReadWrite",
	ORGANIZATIONCHARTREAD = "Organization.Chart.Read",
	ORGANIZATIONCHARTREADWRITE = "Organization.Chart.ReadWrite",
}
