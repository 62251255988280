import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOV } from '@app/compass/interfaces/data-model';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

constructor(private http: HttpClient) { }
  $forceUpdate!: Subject<boolean>;

  forceUpdate(){
    this.$forceUpdate.next(true);
  }

  updateDone(){
    this.$forceUpdate.next(false);
  }

  getCollection(type: string, forceUpdate?: boolean): Observable<LOV[]>{
    return this.http
      .noLoader()
      .delay(3000)
      .cache(forceUpdate)
      .get<LOV[]>(`${environment.routes.collection(type)}`);
  }
}
