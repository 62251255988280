import { Component, ElementRef, ViewChild } from '@angular/core';
import { User, UserConfig } from '@app/compass/interfaces/identity.interface';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { LayoutService } from "./service/app.layout.service";
import { 
    faBars,
    faEllipsisV, 
    faUser
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    userConfig: Observable<UserConfig | undefined>;
    user!: BehaviorSubject<User | null>;

    faBars = faBars;
    faEllipsisV = faEllipsisV;
    faUser = faUser;

    constructor(
        public layoutService: LayoutService
        ) {
            this.userConfig = this.layoutService.configUpdate$;
            this.layoutService.currentUser$.subscribe(user => {
                if(!this.user)
                    this.user = new BehaviorSubject<User | null>(user);
                else
                    this.user.next(user);
            });
        }

    

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
}
