import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { Goal, OrgChartData } from '../interfaces/org.interface';
import { Post } from '../interfaces/wordpress.interface';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OrgService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  getOrgChart(): Observable<OrgChartData> {
    return this.http
      .noLoader()
      .delay(3000)
      .cache()
      .get<OrgChartData>(`${environment.routes.org()}/chart`)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  updateOrgChart(data: OrgChartData): Observable<boolean> {
    return this.http
      .noLoader()
      .delay(3000)
      .put<boolean>(`${environment.routes.org()}/chart`, data)
      .pipe(
        map((resp) => {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.org.chart.update.title'),
            detail: this.translateService.instant('services.org.chart.update.success')
          });
          return resp;
        })
      );
  }

  getPostsFromWP(token: string): Observable<Post[]>{
    let params = new HttpParams();
    let headers = new HttpHeaders();
    
    headers = headers.append('token', token);

    params = params.append('token', token);

    return this.http
      .noLoader()
      .delay(3000)
      .cache()
      .get<Post[]>(`${environment.routes.org()}/news`, { headers });
  }

  getOrgGoals(status?: number): Observable<Goal[]> {
    let params = new HttpParams();

    if(!!status)
      params = params.append('status', status);

    return this.http
      .noLoader()
      .delay(2000)
      .get<Goal[]>(`${environment.routes.org()}/goals`, { params });
  }

  updateOrgGoals(goals: Goal[]): Observable<Goal[]> {
    return this.http
      .noLoader()
      .delay(2000)
      .put<Goal[]>(`${environment.routes.org()}/goals`, goals)
      .pipe(
        map((resp) => {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.org.goals.update.title'),
            detail: this.translateService.instant('services.org.goals.update.success')
          });

          return resp;
        })
      );
  }
}
