<p-sidebar [(visible)]="visible" position="right" [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" styleClass="layout-config-sidebar w-20rem">
    <div class="flex align-items-center">
        <p-avatar *ngIf="!user?.photo" styleClass="mr-2" size="xlarge" shape="circle">
            <fa-icon [icon]="faUser"></fa-icon>
        </p-avatar>
        <p-avatar *ngIf="!!user?.photo" [image]="'data:image/jpeg;base64,' + user?.photo" styleClass="mr-2" size="xlarge" shape="circle"></p-avatar>
        <!-- <p-avatar *ngIf="!!user?.photo" [image]="user?.photo!" styleClass="mr-2" size="xlarge" shape="circle"></p-avatar> -->
        <h4>{{user?.name}}</h4>

        
    </div>
    
    
    <h5 translate>app.configs.scaleSection</h5>
    <div class="flex align-items-center">
        <button type="button" pButton (click)="decrementScale()" class="p-button-text p-button-rounded mr-2" [disabled]="configs.scale === scales[0]">
            <fa-icon [icon]="faMinus"></fa-icon>
        </button>
        
        <div class="flex gap-2 align-items-center">
            <fa-icon [icon]="faCircle" class="text-300" *ngFor="let s of scales" [ngClass]="{'text-primary-500': s === configs.scale}"></fa-icon>
        </div>
        <button type="button" pButton (click)="incrementScale()" class="p-button-text p-button-rounded ml-2" [disabled]="configs.scale === scales[scales.length - 1]">
            <fa-icon [icon]="faPlus"></fa-icon>
        </button>
    </div>

    <h5 translate>app.configs.themeSection</h5>
    <div class="grid">
        <div class="col-12">
            <p-selectButton *ngIf="configs" [options]="themeOptions" [(ngModel)]="configs.colorScheme" optionLabel="label" optionValue="value" (ngModelChange)="themeChanged($event)">
                <ng-template let-item>
                    <img [src]="item.icon" class="w-2rem h-2rem mr-1" alt="{{'app.configs.theme.light' | translate}}">
                    <span translate>{{item.label}}</span>
                </ng-template>
            </p-selectButton>
        </div>
    </div>

    <h5 translate>app.configs.languageSection</h5>
    <div class="grid">
        <div class="col-3">
            <div *ngFor="let country of countries" class="field-checkbox">
                <p-radioButton *ngIf="configs && configs.country" [inputId]="country.code" name="country" [value]="country.code" [(ngModel)]="configs.country" (ngModelChange)="languageChanged($event)"></p-radioButton>
                <div class="ml-3 flex align-items-center">
                    <img src="assets/compass/images/flag/flag_placeholder.png" [class]="country.icon" [alt]="country.language.label">
                    <label [for]="country.code" class="ml-2">{{country.language.label}}</label>
                </div>
            </div>
        </div>
    </div>

    <h5 translate>app.configs.sessionSection</h5>
    <div class="grid">
        <div class="col-12">
            <a (click)="logout()" translate>app.configs.logout</a>
        </div>
    </div>
</p-sidebar>
