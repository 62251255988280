<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/{{(userConfig | async)?.colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo">
        <h1 class="text-2xl font-bold m-0">COMPASS</h1>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <fa-icon [icon]="faBars"></fa-icon>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <fa-icon [icon]="faEllipsisV"></fa-icon>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button class="p-link layout-topbar-button" (click)=(onConfigButtonClick())>
            <fa-icon *ngIf="!(user | async)?.photo" [icon]="faUser"></fa-icon>
            <p-avatar *ngIf="!!(user | async)?.photo" [image]="'data:image/jpeg;base64,' + (user | async)?.photo" styleClass="mr-2" size="xlarge" shape="circle"></p-avatar>
            <!-- <p-avatar *ngIf="!!(user | async)?.photo" [image]="(user | async)?.photo!" styleClass="mr-2" size="xlarge" shape="circle"></p-avatar> -->
            <span>{{ (user | async)?.name }}</span>
        </button>
    </div>
</div>