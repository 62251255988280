import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

import { Logger } from '../logger/logger.service';
import { AuthenticationService } from './authentication.service';

const log = new Logger('AuthorizationGuard');

@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService
  ) {}

  canActivate(): Observable<boolean> {
    return this.authenticationService.logout();
  }
}
