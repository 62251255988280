import { ROUTES } from '../configs/api-routes.constant';
import { COUNTRIES, COUNTRY } from '../configs/countries.constant';

export const environment = {
  production: false,
  hmr: false,
  serviceworker: true,
  serverUrl: '/api',
  directServerUrl: 'https://compass-server-dev.latitudde.com/api',
  proxyAvailable: true,
  proxyEnabled: false,
  mock: true,
  defaultCountry: COUNTRY.PT,
  supportedCountries: COUNTRIES,
  routes: ROUTES,
  google: {
    apiKey: "AIzaSyDpa25Z406QK2xvyk7CHLcgrKWnIhQdkf0"
  },
  isMSALSupported: true,
  MSAL: {
    clientID: '08b85679-6dfe-47c8-aa6a-ee42d3f9be78',
    cloudURL: 'https://login.microsoftonline.com',
    tenantID: 'ccf21c6b-6428-4b56-b3fc-0a5bed4c41cd',
    redirecURI: 'https://compass-dev.latitudde.com/auth/login'
  },
  tricks: {
    mockUserLocation: true,
  },
};
