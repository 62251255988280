import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './compass/components/notfound/notfound.component';
import { CoreModule } from './core/core.module';
import { EnvironmentClass } from './core/environment/environment.class';
import { environment } from '@env/environment';
import { ENV } from '@conf/injection-tokens.constant';
import { TranslateModule } from '@ngx-translate/core';
import { IdentityService } from './compass/services/identity.service';
import { OrgService } from './compass/services/org.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TalentService } from './compass/services/talent.service';
import { CollectionService } from './compass/services/collection.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CommonModule } from '@angular/common';

const SERVICES = [
    IdentityService, 
    OrgService,
    TalentService,
    CollectionService
];

const env = new EnvironmentClass(environment.defaultCountry);
const locale = env.defaultLanguage.locale;

export function envFactory() {
    return env;
}

@NgModule({
    declarations: [
        AppComponent, NotfoundComponent
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        CoreModule,
        CommonModule,
        ProgressSpinnerModule,
        TranslateModule.forRoot(),
        FontAwesomeModule
    ],
    providers: [
        ...SERVICES,
        {
            provide: ENV,
            useFactory: envFactory,
        },
        {
            provide: LOCALE_ID,
            useValue: locale,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
