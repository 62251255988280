import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { environment } from '@env/environment';

import { EnvironmentService } from '../../environment/environment.service';

import { Logger } from '../../logger/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { RefreshResponse } from '@app/core/authentication/authentication.interface';

const log = new Logger('HTTPErrorHandlerInterceptor');
const retryCount = 0;

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public appEnvironment: EnvironmentService,
    public router: Router,
    private injector: Injector,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  /**
   * Adds a default error handler to all requests.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !error.url?.includes("token/refresh")) {
          return this.handle401Error(request, next, error);
        } else {
          return this.errorHandler(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, response: HttpErrorResponse){
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const authService = this.injector.get(AuthenticationService);
      const storedRefreshToken = this.appEnvironment.currentCredentialsSubject.getValue()?.refreshToken;

      if(storedRefreshToken){
        return authService.refresh(storedRefreshToken).pipe(
          switchMap((result: RefreshResponse) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(result.token);
            return next.handle(request);
          }),
          catchError(response => this.errorHandler(response))
        );
      }
      else 
        return this.errorHandler(response);
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => {
          return next.handle(request);
        })
      );
    }
  }

  private errorHandler(response: any): Observable<any> {
    if (response instanceof HttpErrorResponse) {
      if (response.status === 401) {
        log.error('Request error', 'Unauthorized.');

        this.appEnvironment.saveCredentials();
        this.appEnvironment.saveCurrentUser();
        this.appEnvironment.savePrivileges();
        this.appEnvironment.clearTokenData();

        this.messageService.add({ severity:'error', summary: this.translateService.instant('global.error.title'), detail: this.translateService.instant('global.error.401') });

        if(!(window.location.href.includes("profile-generator") && window.location.href.includes("token")))
          this.router.navigate(['auth','login'], { replaceUrl: true });
      } else if (response.status === 400) {
          this.messageService.add({ severity:'error', summary: this.translateService.instant('global.error.title'), detail: this.translateService.instant('global.error.400') });
          log.error(response.message);
      } else if (response.status === 403) {
          this.messageService.add({ severity:'error', summary: this.translateService.instant('global.error.title'), detail: this.translateService.instant('global.error.403') });
          log.error(response.message);
      } else if (response.status === 404) {
          this.messageService.add({ severity:'error', summary: this.translateService.instant('global.error.title'), detail: this.translateService.instant('global.error.404') });
          log.error(response.message);
      } else if (response.status === 410) {
          this.messageService.add({ severity:'error', summary: this.translateService.instant('global.error.title'), detail: this.translateService.instant('global.error.410') });
          log.error(response.message);
      } else if (response.status === 412) {
          this.messageService.add({ severity:'error', summary: this.translateService.instant('global.error.title'), detail: this.translateService.instant('global.error.412') });
          log.error(response.message);
      } else if (response.status === 0 || response.status === 500) {
          this.messageService.add({ severity:'error', summary: this.translateService.instant('global.error.title'), detail: this.translateService.instant('global.error.500') });
          log.error(response.message);
      } else if (response.message) {
          this.messageService.add({ severity:'error', summary: this.translateService.instant('global.error.title'), detail: response.message });
          log.error(response.message);
      }
    }

    if (!environment.production) {
      log.error('Request error', response);
    }

    throw response;
  }
}
