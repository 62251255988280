import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';

import { HttpCacheService } from '../http-cache.service';
import { I18nService } from '@app/core/i18n/i18n.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private forceUpdate = false;

  constructor(
    private httpCacheService: HttpCacheService,
    private i18n: I18nService
    ) {}

  /**
   * Configures interceptor options
   */
  configure(options?: { update?: boolean } | null): CacheInterceptor {
    const instance = new CacheInterceptor(this.httpCacheService, this.i18n);

    if (options && options.update) {
      instance.forceUpdate = true;
    }

    return instance;
  }

  /**
   * Caches HTTP requests
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    return new Observable((subscriber: Subscriber<HttpEvent<any>>) => {
      let language = this.i18n.language;
      const cachedData = this.forceUpdate ? null : this.httpCacheService.getCacheData(request.urlWithParams, language);

      if (cachedData !== null && cachedData.url) {
        // Create new response to avoid side-effects
        subscriber.next(new HttpResponse({
          body: cachedData.body,
          headers: cachedData.headers,
          status: cachedData.status,
          statusText: cachedData.statusText,
          url: cachedData.url
        }));

        subscriber.complete();
      } else {
        next.handle(request).subscribe({
          next: (event) => {
            if (event instanceof HttpResponse) {
              this.httpCacheService.setCacheData(request.urlWithParams, event, language);
            }

            subscriber.next(event);
          },
          error: (error) => subscriber.error(error),
          complete: () => subscriber.complete()
        });
      }
    });
  }
}
