import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from "../service/app.layout.service";
import { MenuService } from "../app.menu.service";
import { COUNTRIES, COUNTRYCONFIG } from '@conf/countries.constant';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { User, UserConfig } from '@app/compass/interfaces/identity.interface';
import { 
    faCircle,
    faMinus,
    faPlus,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
    selector: 'app-config',
    templateUrl: './app.config.component.html'
})
export class AppConfigComponent implements OnInit {

    @Input() minimal: boolean = false;

    scales: number[] = [12, 13, 14, 15, 16];
    themeOptions: any[];
    countries: COUNTRYCONFIG[];
    configs: UserConfig;
    user!: User | null;

    faCircle = faCircle;
    faMinus = faMinus;
    faPlus = faPlus;
    faUser = faUser;

    constructor(
        public layoutService: LayoutService,
        public menuService: MenuService,
        private router: Router
        ) {
        this.themeOptions = [{
            label: 'app.configs.theme.light',
            value: 'light',
            icon: 'assets/layout/images/logo-white.svg'
        }, {
            label: 'app.configs.theme.dark',
            value: 'dark',
            icon: 'assets/layout/images/logo-dark.svg'
        }];

        this.countries = COUNTRIES;
        this.configs = this.layoutService.defaultConfig;
    }

    ngOnInit() {
        this.layoutService.configUpdate$.subscribe(configs => {
            this.configs = configs ?? this.layoutService.defaultConfig;
        })
        
        this.layoutService.currentUser$.subscribe(user => {
            this.user = user;
        })
    }

    get visible(): boolean {
        return this.layoutService.state.configSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.configSidebarVisible = _val;
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    decrementScale() {
        this.layoutService.decrementScale();
    }

    incrementScale() {
        this.layoutService.incrementScale();
    }

    themeChanged(theme: string) {
        this.layoutService.changeTheme(theme, true);
    }

    languageChanged(lang: string) {
        this.layoutService.changeLanguage(lang, true);
    }

    logout() {
        this.router.navigate(['auth', 'logout']);
    }
}
