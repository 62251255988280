<ng-container>
    <div *ngIf="root && area.visible !== false" class="layout-menuitem-root-text" translate>{{area.name}}</div>
    <a *ngIf="(!area.route || (area.subAreas && area.subAreasVisible)) && area.visible !== false" (click)="itemClick()" tabindex="0" pRipple>
        <fa-icon [icon]="area.icon ?? 'circle-question'" class="layout-menuitem-icon"></fa-icon>
        <span class="layout-menuitem-text" translate>{{area.name}}</span>
        <fa-icon icon="angle-down" class="layout-submenu-toggler" *ngIf="area.subAreas && area.subAreasVisible"></fa-icon>
    </a>
    <a *ngIf="(area.route && (!area.subAreas || (area.subAreas && !area.subAreasVisible))) && area.visible !== false" (click)="itemClick()" 
       [routerLink]="area.route" routerLinkActive="active-route" [routerLinkActiveOptions]="area.routerLinkActiveOptions||{ paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }"
        tabindex="0" pRipple>
        <fa-icon [icon]="area.icon ?? 'circle-question'" class="layout-menuitem-icon"></fa-icon>
        <span class="layout-menuitem-text" translate>{{area.name}}</span>
        <fa-icon icon="angle-down" class="layout-submenu-toggler" *ngIf="area.subAreas && area.subAreasVisible"></fa-icon>
    </a>

    <ul *ngIf="area.subAreas && area.visible !== false" [@children]="submenuAnimation">
        <ng-template ngFor let-child let-i="index" [ngForOf]="area.subAreas">
            <li app-menuitem [area]="child" [index]="i" [parentKey]="key"></li>
        </ng-template>
    </ul>
</ng-container>