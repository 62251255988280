import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvironmentService } from '../../environment/environment.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public environmentService: EnvironmentService) {}

  /**
   * Injects the Bearer Token in every interface call
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.includes("maps.google.com"))
      return next.handle(request);

      const token = this.environmentService.currentCredentialsSubject.getValue()?.token;

    if (this.environmentService.isAuthenticated() && token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request);
  }
}
