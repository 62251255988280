import { Component } from '@angular/core';
import { UserConfig } from '@app/compass/interfaces/identity.interface';
import { Observable } from 'rxjs';
import { LayoutService } from "./service/app.layout.service";

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {

    userConfig: Observable<UserConfig | undefined>;

    constructor(public layoutService: LayoutService) {
        this.userConfig = this.layoutService.configUpdate$;
    }
}
