import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { MsalModule, MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { TranslateModule } from '@ngx-translate/core';

/**
 * Other
 */
import { RouteReusableStrategy } from './route-strategy/route-reusable-strategy';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { AuthorizationGuard } from './authentication/authorization.guard';
import { LogoutGuard } from './authentication/logout.guard';

/**
 * SERVICES
 */
import { I18nService } from './i18n/i18n.service';
import { HttpService } from './http/http.service';
import { HttpCacheService } from './http/http-cache.service';
import { EnvironmentService } from './environment/environment.service';
import { AreasService } from './areas/areas.service';
import { AuthenticationService } from './authentication/authentication.service';
import { LoaderService } from './loader/loader.service';

/**
 * HTTP INTERCEPTORS
 */
import { ApiPrefixInterceptor } from '@core/http/interceptors/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from '@core/http/interceptors/error-handler.interceptor';
import { CacheInterceptor } from '@core/http/interceptors/cache.interceptor';
import { TokenInterceptor } from '@core/http/interceptors/token.interceptor';
import { LanguageInterceptor } from '@core/http/interceptors/language.interceptor';
import { FullPageLoaderInterceptor } from '@core/http/interceptors/full-page-loader.interceptor';
import { MockInterceptor } from '@core/http/interceptors/mock.interceptor';

import { environment } from '@env/environment';
import { MessageService } from 'primeng/api';
import { msalScopes } from '@conf/helper.functions';

const msalConfig = {
  auth: {
    clientId: environment.MSAL.clientID,
    authority: `${environment.MSAL.cloudURL}/${environment.MSAL.tenantID}`,
    redirectUri: environment.MSAL.redirecURI
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};

const guardConfig : MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect
};

const interceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap : new Map<string, Array<string>>().set("https://graph.microsoft.com/v1.0/me", msalScopes)
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    RouterModule,
    MsalModule.forRoot(new PublicClientApplication(msalConfig), guardConfig, interceptorConfig),
  ],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    AuthorizationGuard,
    LogoutGuard,
    EnvironmentService,
    AreasService,
    LoaderService,
    I18nService,
    HttpCacheService,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    FullPageLoaderInterceptor,
    TokenInterceptor,
    LanguageInterceptor,
    MockInterceptor,
    CacheInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    MessageService
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
