import { Validators } from '@angular/forms';
import { User } from '@app/compass/interfaces/identity.interface';
import { OrgChartData } from '@app/compass/interfaces/org.interface';


export const EmailValidation = Validators.pattern(
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
);

export const NumberValidation = Validators.pattern(/^[0-9]*$/);

export const DecimalNumberValidation =
  Validators.pattern(/^[0-9]+[.,]?[0-9]*$/);

export const PositiveNumberValidation = Validators.pattern(/^[1-9][0-9]*$/);

export const msalScopes = ["user.read", "Mail.Read", "OnlineMeetings.ReadWrite", "Calendars.ReadWrite"];

export const usertoOrgChartData = (x: User): OrgChartData => {
  return {
      id: x.id,
      label: x.roles?.toString() ?? "",
      expanded: true,
      styleClass: "p-people",
      data: {
        name: x.name,
        avatar: x.photo
      }
  }
}

export function findChildNodesOfUser( orgChartData: OrgChartData,loggedInUserId: string): OrgChartData[] {
  const userChildNodes: OrgChartData[] = [];

  // Find the user's node
  const userNode = findNodeById(orgChartData, loggedInUserId);

  if (!userNode) {
    return userChildNodes; // User not found in the tree
  }

  // Get the children of the user's node
  if (userNode.children) {
    userChildNodes.push(...userNode.children);
  }

  return userChildNodes;
}

// Helper function to find a node by its ID in the tree
export function findNodeById(node: OrgChartData, targetId: string): OrgChartData | null {
  if(node === undefined)
    return null;
  
  if (node.id === targetId) {
    return node;
  }

  if (node.children) 
  {
    for (const child of node.children)
    {
      const foundNode = findNodeById(child, targetId);
      if (foundNode) 
      {
        return foundNode;
      }
    }
  }

  return null;
}

export function findParentNodes(node: OrgChartData, targetId: string): OrgChartData[] {
  const parentNodes: OrgChartData[] = [];
  var hasValue: OrgChartData[] = [];

  if(node.id === targetId) 
  {
    parentNodes.push(node);
    return parentNodes;
  }
    
  if (node.children) 
  {
    for (const child of node.children) 
    {
      hasValue = findParentNodes(child, targetId);

      if(hasValue.length > 0)
      {
        parentNodes.push(...hasValue);
        parentNodes.push(node);
      }
        
    }
  }
    
  return parentNodes;
}

